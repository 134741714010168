<template>
  <circle cx="17.5" cy="12.5" r="8.5" fill="#EDF4FE" />
  <rect x="8" y="24" width="2" height="3" rx="1" fill="#92AFEA" />
  <rect x="20" y="24" width="2" height="3" rx="1" fill="#92AFEA" />
  <path
    d="M3.87748 18.6325C3.4458 17.3374 4.40974 16 5.77485 16H24.2251C25.5903 16 26.5542 17.3374 26.1225 18.6325L24.4558 23.6325C24.1836 24.4491 23.4193 25 22.5585 25H7.44152C6.58066 25 5.81638 24.4491 5.54415 23.6325L3.87748 18.6325Z"
    fill="#3769D6"
  />
  <path
    d="M23 15V6C23 5.44772 22.5523 5 22 5H19"
    stroke="#92AFEA"
    stroke-linecap="round"
  />
  <path
    d="M6 19L7.27208 22.8162C7.40819 23.2246 7.79033 23.5 8.22076 23.5H9"
    stroke="#92AFEA"
    stroke-linecap="round"
  />
  <path
    d="M20.8809 7.70132C20.7905 8.53631 19.7734 8.89475 19.1795 8.30088L16.4765 5.59781C15.8826 5.00395 16.241 3.98683 17.076 3.89651L18.3519 3.75848C18.653 3.72592 18.9525 3.83149 19.1666 4.04558L20.7318 5.61076C20.9459 5.82484 21.0514 6.1244 21.0189 6.42541L20.8809 7.70132Z"
    fill="#3769D6"
  />
  <path d="M16 7L15 8" stroke="#92AFEA" stroke-linecap="round" />
  <path d="M15 10L14 11" stroke="#92AFEA" stroke-linecap="round" />
  <path d="M18 10L17 11" stroke="#92AFEA" stroke-linecap="round" />
</template>
